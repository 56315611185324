import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { useNavigate, useParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';

import { ActiveFiltersOfJobs } from 'src/components/ActiveFiltersOfJobs';
import { FilterDrawer } from 'src/components/FilterDrawer';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { JobsTable } from 'src/features/JobsTable';
import { ActionBar } from 'src/features/JobsTable/components/ActionBar';
import { useJobs } from 'src/features/JobsTable/utilities/hooks';
import { SelectedRows } from 'src/features/JobsTable/utilities/hooks/useJobs';
import { useUpdateAssignToProjectMutation } from 'src/pages/AssignToProject/AssignToProject.service';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { useAppDispatch } from 'src/utilities/hooks';
import { STACK_STYLES } from 'src/utilities/stylesOfJobs';

type ProjectAssignProps = { possibleJobIdsToBeAssigned: number[] };

export function ProjectAssign({ possibleJobIdsToBeAssigned }: ProjectAssignProps) {
  const {
    areActiveFiltersVisible,
    areJobsFetching,
    columns,
    deadlines,
    handleChangePageSearch,
    handleClickRowRadio,
    handleToggleActiveFiltersVisibility,
    rows,
    searchTerm,
    selectedRows,
    setAreActiveFiltersVisible,
    setPageHistory,
    setSelectedRows,
    totalRowCount,
  } = useJobs();

  const dispatch = useAppDispatch();
  const { jobType } = useParams();

  const navigate = useNavigate();

  const [jobIdsToBeAssigned, setJobIdsToBeAssigned] = useState<SelectedRows>(
    possibleJobIdsToBeAssigned,
  );
  const [successAssignedJobIds, setSuccessAssignedJobIds] = useState<Array<number>>([]);
  const [updateAssignToProject, { isLoading }] = useUpdateAssignToProjectMutation();

  function handleCancel() {
    navigate(`/jobs-job-${jobType}`);
    setSelectedRows([]);
  }

  async function handleConfirm() {
    jobIdsToBeAssigned.map((item) => {
      updateAssignToProject({
        assignToJobId: selectedRows[0],
        assignToJobType: 'pro',
        jobId: item,
        jobType,
      })
        .unwrap()
        .then((message) => {
          dispatch(
            openWaveSnack({
              message,
              type: 'success',
            }),
          );
          setSuccessAssignedJobIds((previousId) => [...previousId, item]);
        });
    });
  }

  function handleToggleSelectedJob(e: React.ChangeEvent<HTMLInputElement>) {
    const jobId = parseInt(e.target.name);

    setJobIdsToBeAssigned((previousJobIdsToBeAssigned) =>
      !previousJobIdsToBeAssigned.includes(jobId)
        ? [...previousJobIdsToBeAssigned, jobId]
        : previousJobIdsToBeAssigned.filter((jobIdToBeAssigned) => jobIdToBeAssigned !== jobId),
    );
  }

  useEffect(() => {
    if (successAssignedJobIds.length === jobIdsToBeAssigned.length) {
      navigate(`/jobs-job-${jobType}`);
    }
  }, [successAssignedJobIds]);

  return (
    <Stack overflow="hidden">
      <Stack alignItems="center" direction="row" justifyContent="center" p={2}>
        <Typography fontWeight="bold" m="auto" variant="h5">
          Select a Project
        </Typography>

        <Stack direction="row" gap={1}>
          <Button color="warning" onClick={handleCancel}>
            <Trans i18nKey="lib.dialog.cancel">Cancel</Trans>
          </Button>

          <WaveTooltip
            body={
              selectedRows.length === 0
                ? 'Please select a project.'
                : jobIdsToBeAssigned.length === 0
                ? 'Please select a job ID.'
                : ''
            }
            component={
              <LoadingButton
                disabled={isLoading || selectedRows.length === 0 || jobIdsToBeAssigned.length === 0}
                loading={isLoading}
                onClick={handleConfirm}
                variant="contained"
              >
                <Trans i18nKey="lib.confirm">Cancel</Trans>
              </LoadingButton>
            }
            placement="top"
            type="simple"
          />
        </Stack>
      </Stack>

      <Divider />

      <Stack direction="row" overflow="hidden">
        <FormGroup
          sx={{ flexShrink: 0, flexWrap: 'nowrap', overflow: 'auto', px: 2, py: 0.5, width: 125 }}
        >
          {possibleJobIdsToBeAssigned.map((row: number) => (
            <FormControlLabel
              componentsProps={{
                typography: {
                  variant: 'body2',
                },
              }}
              control={
                <Checkbox
                  checked={jobIdsToBeAssigned.indexOf(row) !== -1}
                  name={row.toString()}
                  onChange={(e) => handleToggleSelectedJob(e)}
                />
              }
              key={row}
              label={row}
            />
          ))}
        </FormGroup>

        <Divider orientation="vertical" />

        <Stack overflow="hidden">
          <Box m={1}>
            <ActionBar
              onChangePageSearch={handleChangePageSearch}
              onToggleActiveFiltersVisibility={handleToggleActiveFiltersVisibility}
              searchTerm={searchTerm}
              selectedRows={selectedRows}
            />

            <ActiveFiltersOfJobs
              areActiveFiltersVisible={areActiveFiltersVisible}
              setAreActiveFiltersVisible={setAreActiveFiltersVisible}
            />
          </Box>

          <Stack {...STACK_STYLES}>
            <FilterDrawer
              areActiveFiltersVisible={areActiveFiltersVisible}
              handleToggleActiveFiltersVisibility={handleToggleActiveFiltersVisibility}
              parentLocation="project-assign"
            />

            <JobsTable
              areActiveFiltersVisible={areActiveFiltersVisible}
              areJobsFetching={areJobsFetching}
              columns={columns}
              deadlines={deadlines}
              onClickRowRadio={handleClickRowRadio}
              rows={rows}
              selectedRows={selectedRows}
              setPageHistory={setPageHistory}
              setSelectedRows={setSelectedRows}
              totalRowCount={totalRowCount}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
