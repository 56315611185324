import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material';

import { RowProps } from 'src/features/JobsTable/components/Row/Row';
import { useGetCopyJobOptionsQuery } from 'src/features/JobsTable/JobsTable.service';
import { isArchivedStatus } from 'src/features/JobsTable/utilities/helperFunctions';
import { useGetWorkflowQuery } from 'src/features/Workflow/Workflow.service';
import { isString } from 'src/utilities/helperFunctions2';
import { useAppSelector, usePreferencePrefix } from 'src/utilities/hooks';
import { usePagePreference } from 'src/utilities/hooks/usePagePreference';
import { usePreferenceAsNumber } from 'src/utilities/hooks/usePreferenceAsNumber';
import { BasicAge } from 'src/utilities/hooks/useRouteParams';

export type UseRowProps = Required<
  Pick<
    RowProps,
    | 'canTablePossiblyHaveADeletableRow'
    | 'deadline'
    | 'idsOfExpandedJobs'
    | 'isDense'
    | 'isSubRow'
    | 'jobIdsWithSubJobs'
    | 'selectedRows'
    | 'totalRowCount'
  >
> &
  Pick<
    RowProps,
    'onClickRowCheckbox' | 'onOpenConfirmationDialog' | 'row' | 'rowId' | 'setIdsOfExpandedJobs'
  >;

export function useRow({
  canTablePossiblyHaveADeletableRow,
  deadline,
  idsOfExpandedJobs,
  isDense,
  isSubRow,
  jobIdsWithSubJobs,
  onClickRowCheckbox,
  onOpenConfirmationDialog,
  row,
  rowId,
  selectedRows,
  setIdsOfExpandedJobs,
  totalRowCount,
}: UseRowProps) {
  const navigate = useNavigate();
  const {
    palette: {
      error: { main: error },
      filler,
      oddRow: { main: oddRow },
      warning: { main: warning },
    },
  } = useTheme();

  const jobId = isString(row.jobId) ? parseInt(row.jobId) : row.jobId;
  const status = row.status;
  const { age, isAssignJobTable, isSubJobsTable, jobType, preferencePrefix } = usePreferencePrefix({
    forcedAge: isArchivedStatus(status) ? 'arc' : 'job',
  });
  const isAgeBasic = age === 'arc' || age === 'job';

  const [isCopyJobConfirmationDialogOpen, setIsCopyJobConfirmationDialogOpen] = useState(false);
  const { data: copyJobOptions, isFetching: areCopyJobOptionsFetching } = useGetCopyJobOptionsQuery(
    {
      age,
      jobId: jobId,
      jobType: row.jobType,
    },
    { skip: !isCopyJobConfirmationDialogOpen },
  );

  const pagePreference = usePagePreference({ preferencePrefix, totalRowCount });
  const rowsPerPage = usePreferenceAsNumber({
    code: `${preferencePrefix}.rowsPerPage`,
    defaultValue: '25',
  });
  const accumulativeRowId = rowsPerPage.value * pagePreference.value + (rowId + 1);

  const { data: workflow } = useGetWorkflowQuery(
    {
      age: age as BasicAge,
      jobType: row.jobType,
    },
    { skip: !isAgeBasic },
  );

  const menuItems = useAppSelector((state) => state.user.mainMenu);
  const accessibleJobs = menuItems.find((item) => item.code === 'job')?.subitems;

  const hasInsertRights =
    accessibleJobs?.length &&
    useAppSelector((state) =>
      accessibleJobs.map((item) => item.code).some((job) => state.user.rights.insert.includes(job)),
    );
  const deleteRights = useAppSelector((state) => state.user.rights.delete);
  const canDelete =
    !!workflow?.[status]?.flags.canDelete && deleteRights.includes(`job-${row.jobType}`);

  const cellPaddingY = isDense ? 0 : 0.5;
  const isRowExpanded = idsOfExpandedJobs?.includes(jobId);
  const commonCodePrefix = 'jobs-table-row';
  const arrowDirection = isRowExpanded ? 'up' : 'down';
  const arrowCode = `${commonCodePrefix}-arrow-${arrowDirection}`;
  const copyCode = `${commonCodePrefix}-copy`;
  const deleteCode = `${commonCodePrefix}-delete`;
  const copyColsSpan = !canDelete && canTablePossiblyHaveADeletableRow ? 2 : 1;
  const shouldHaveExpandCollapseColumn = !!jobIdsWithSubJobs.length;
  const shouldHaveExpandCollapseButton =
    shouldHaveExpandCollapseColumn && jobIdsWithSubJobs.includes(jobId);
  const shouldHaveCheckbox =
    jobType !== 'dash' && !isSubRow && !isSubJobsTable && !isAssignJobTable;
  const isSelected = selectedRows.indexOf(jobId) !== -1;
  const canPossiblyHaveActions = !isSubRow && jobType !== 'dash' && !isAssignJobTable;
  const jobLink = `/jobs-${age}-${row.jobType}/${jobId}`;

  const deadlineValue = Object.values(deadline)[0];
  const deadlineStyle = deadlineValue
    ? { borderLeft: 10, color: deadlineValue === 'late' ? error : warning, pl: 1, pr: 2 }
    : undefined;
  const oddRowStyle = rowId % 2 !== 0 ? { bgcolor: oddRow } : {};

  function handleClickRow(e: React.MouseEvent<HTMLDivElement>) {
    e.preventDefault();

    // Temp solution, until we have the following job types implemented in 3.0
    const hubxLegacyUrl = import.meta.env.VITE_HUBX_LEGACY_URL;

    if ((jobType === 'com' || jobType === 'sec') && hubxLegacyUrl) {
      window.open(
        `${hubxLegacyUrl}index.php?act=${
          isArchivedStatus(status) ? 'arc' : 'job'
        }-${jobType}.edt&jobid=${jobId}`,
        '_blank',
      );
    } else {
      navigate(`/jobs-${isArchivedStatus(status) ? 'arc' : 'job'}-${row.jobType}/${jobId}/job`);
    }
  }

  function handleClickCheckbox(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation();

    if (onClickRowCheckbox) onClickRowCheckbox(jobId);
  }

  function handleClickExpandCollapse(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();
    if (!setIdsOfExpandedJobs || !idsOfExpandedJobs) return;

    if (!isRowExpanded) {
      setIdsOfExpandedJobs([...idsOfExpandedJobs, jobId]);
    } else {
      setIdsOfExpandedJobs(idsOfExpandedJobs.filter((expandedJobId) => expandedJobId !== jobId));
    }
  }

  function handleDeleteJob(e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();
    if (onOpenConfirmationDialog) {
      onOpenConfirmationDialog({
        jobId,
        jobType: row.jobType,
      });
    }
  }

  function handleOpenCopyJobDialog(e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();
    setIsCopyJobConfirmationDialogOpen(true);
  }

  function handleCloseCopyJobDialog() {
    setIsCopyJobConfirmationDialogOpen(false);
  }

  return {
    accumulativeRowId,
    areCopyJobOptionsFetching,
    arrowCode,
    canDelete,
    canPossiblyHaveActions,
    cellPaddingY,
    copyCode,
    copyColsSpan,
    copyJobOptions,
    deadlineStyle,
    deleteCode,
    filler,
    handleClickCheckbox,
    handleClickExpandCollapse,
    handleClickRow,
    handleCloseCopyJobDialog,
    handleDeleteJob,
    handleOpenCopyJobDialog,
    hasInsertRights,
    isCopyJobConfirmationDialogOpen,
    isRowExpanded,
    isSelected,
    jobLink,
    oddRowStyle,
    shouldHaveCheckbox,
    shouldHaveExpandCollapseButton,
    shouldHaveExpandCollapseColumn,
    status,
  };
}
