import { FormEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useActiveFiltersOfJobs } from 'src/components/ActiveFiltersOfJobs/useActiveFiltersOfJobs';
import { FilterDrawerProps } from 'src/components/FilterDrawer/FilterDrawer';
import {
  computeChangedFormFilters,
  computeDefaultValues,
  extractFieldDataByAlias,
  filterFormFiltersByAlias,
  filterValueFromCommaSeparatedString,
  haveFiltersChanged as computeHaveFiltersChanged,
} from 'src/components/FilterDrawer/utilities/helperFunctions';
import { useFieldSelectorFields } from 'src/features/JobsTable/utilities/hooks';
import { FieldTransformed, FieldValue, FieldValues } from 'src/pages/Job/Job.service';
import { isString } from 'src/utilities/helperFunctions2';
import { usePreference, usePreferencePrefix } from 'src/utilities/hooks';
import { ActiveFilter } from 'src/utilities/hooks/usePreference';

type PropsOfUseFilters = Pick<FilterDrawerProps, 'areActiveFiltersVisible' | 'parentLocation'>;

export function useFilterDrawer({ areActiveFiltersVisible, parentLocation }: PropsOfUseFilters) {
  const { t: translate } = useTranslation();
  const useFormReturn = useForm();
  const { getValues, reset, setValue, watch } = useFormReturn;

  const [isFieldSelectorOpen, setIsFieldSelectorOpen] = useState(false);

  const { preferencePrefix } = usePreferencePrefix();

  const pagePreference = usePreference(`${preferencePrefix}.listPage`, '0');

  const searchFiltersPreference = usePreference(`${preferencePrefix}.filterFields`, '');
  const { savedAvailableFields, savedSelectedFields } = useFieldSelectorFields({
    fieldPreference: searchFiltersPreference,
    isFieldSelectorOpen: !areActiveFiltersVisible,
    type: 'search',
  });

  const { activeFilters, areActiveFiltersLoading, setActiveFilters } = useActiveFiltersOfJobs();

  const [formFilters, setFormFilters] = useState<ActiveFilter[]>([]);
  const haveFiltersChanged = computeHaveFiltersChanged(activeFilters, formFilters);
  const location = `${parentLocation}-filter-drawer`;

  function handleFieldChange(
    formData: FieldValues,
    savedSelectedFields: FieldTransformed[],
    changedFieldAlias?: string,
  ) {
    if (!changedFieldAlias) return;

    const {
      fieldData: changedFieldOptions,
      name: changedFieldLabel,
      type: changedFieldType,
    } = extractFieldDataByAlias(changedFieldAlias, savedSelectedFields);
    const changedFieldValue = changedFieldAlias ? formData[changedFieldAlias] : undefined;

    if (
      changedFieldLabel &&
      changedFieldOptions &&
      Array.isArray(changedFieldOptions) &&
      changedFieldType &&
      changedFieldValue !== undefined
    ) {
      const changedFormFilters = computeChangedFormFilters(
        changedFieldAlias,
        changedFieldLabel,
        changedFieldOptions,
        changedFieldType,
        changedFieldValue,
      );

      setFormFilters((previousFormFilters) => {
        // Filter out all formFilters for this field to start fresh
        const previousFormFiltersFiltered = filterFormFiltersByAlias(
          previousFormFilters,
          changedFieldAlias,
        );

        return [...previousFormFiltersFiltered, ...changedFormFilters];
      });
    }
  }

  function handleCloseFieldSelector() {
    setIsFieldSelectorOpen(false);
  }

  function handleOpenFieldSelector() {
    setIsFieldSelectorOpen(true);
  }

  function handleApplyFieldSelector(selectedFields?: FieldTransformed[]) {
    searchFiltersPreference.set(selectedFields?.map(({ id }) => id).join() || '');
  }

  function handleDeleteFormFilter(deleteFilter: ActiveFilter) {
    const deleteFilterKey = Object.keys(deleteFilter)[0];
    const deleteFilterValue = deleteFilter[deleteFilterKey];
    const fieldValue: FieldValue = getValues(deleteFilterKey);

    if (fieldValue === true) setValue(deleteFilterKey, !fieldValue);

    if (isString(fieldValue)) {
      const filteredValue = filterValueFromCommaSeparatedString(deleteFilterValue, fieldValue);

      setValue(deleteFilterKey, filteredValue);
    }
  }

  function handleDeleteAllFormFilters() {
    if (savedSelectedFields) resetForm(savedSelectedFields);
  }

  function resetForm(savedSelectedFields: FieldTransformed[], activeFilters?: ActiveFilter[]) {
    const defaultValues = computeDefaultValues(savedSelectedFields, activeFilters);

    reset(defaultValues);
    setFormFilters(activeFilters ?? []);
  }

  function handleSubmitForm(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setActiveFilters(formFilters);
    pagePreference.set('0');
  }

  useEffect(() => {
    if (savedSelectedFields) resetForm(savedSelectedFields, activeFilters);
  }, [activeFilters, savedSelectedFields]);

  useEffect(() => {
    if (!savedSelectedFields) return;

    const subscription = watch((formData, { name: changedFieldAlias }) =>
      handleFieldChange(formData, savedSelectedFields, changedFieldAlias),
    );

    return () => subscription.unsubscribe();
  }, [savedSelectedFields, watch]);

  return {
    areActiveFiltersLoading,
    formFilters,
    handleApplyFieldSelector,
    handleCloseFieldSelector,
    handleDeleteAllFormFilters,
    handleDeleteFormFilter,
    handleOpenFieldSelector,
    handleSubmitForm,
    haveFiltersChanged,
    isFieldSelectorOpen,
    location,
    savedAvailableFields,
    savedSelectedFields,
    searchFiltersPreference,
    translate,
    useFormReturn,
  };
}
