import { ChangeEvent, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import i18next from 'i18next';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';

import { WaveDialogTitle } from 'src/components/WaveDialogTitle';
import {
  StyledRadioButton,
  StyledRadioGroup,
} from 'src/features/CompareReplaceDialog/components/CompareReplaceAccordionDetails/CompareReplaceAccordionDetails';
import { OFFSET_COLUMN_WIDTH } from 'src/features/CompareReplaceDialog/components/CompareReplaceContent/utils';
import { DialogHeader } from 'src/features/IntegrateUpdateDialog/components/DialogHeader';
import { DialogList } from 'src/features/IntegrateUpdateDialog/components/DialogList';
import { SearchBar } from 'src/features/IntegrateUpdateDialog/components/SearchBar';
import { getFilterSameValues, getFilterSearchTerm } from 'src/features/IntegrateUpdateDialog/util';
import {
  IntegrateUpdateResponse,
  jobFormApi,
  useGetIntegrateUpdateQuery,
  useUpdateJobMutation,
} from 'src/features/JobForm/JobForm.service';
import { workflowApi } from 'src/features/Workflow/Workflow.service';
import { StyledHeaderContainer } from 'src/pages/FileSearch/FileSearchGridItem/styled';
import { FieldsTransformed, FieldValues, jobApi, OtherJobValues } from 'src/pages/Job/Job.service';
import { useJobContext } from 'src/pages/Job/JobContext';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { useAppDispatch, useRouteParams } from 'src/utilities/hooks';

type IntegrateUpdateDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function IntegrateUpdateDialog({ isOpen, onClose }: IntegrateUpdateDialogProps) {
  const { age, jobId, jobType } = useRouteParams();
  const { data: { integrate = {}, job = {} } = {}, isFetching } = useGetIntegrateUpdateQuery(
    { age, jobId, jobType },
    { skip: !isOpen },
  );

  const [searchTerm, setSearchTerm] = useState('');
  const [shouldHideSameValues, setShouldHideSameValues] = useState<boolean>(false);
  const { language } = i18next;
  const { t } = useTranslation();
  const { fields: jobFields, values: jobData } = useJobContext();
  const dispatch = useAppDispatch();

  const { control, getValues, reset, setValue, watch } = useForm();
  const [updateJob, { isLoading }] = useUpdateJobMutation();

  const selectedFields = watch('selectedFields');

  const filteredSameValuesData = shouldHideSameValues
    ? getFilterSameValues({ integrate, job } as IntegrateUpdateResponse)
    : { integrate, job };

  const filteredSearchData = searchTerm
    ? getFilterSearchTerm(
        filteredSameValuesData as IntegrateUpdateResponse,
        jobFields as FieldsTransformed,
        searchTerm,
      )
    : filteredSameValuesData;

  const totalUpdatedCount = Object.values(selectedFields || {}).filter(
    (value) => value === 'updated',
  ).length;

  const totalFieldsCount = Object.keys(filteredSearchData?.integrate).filter(
    (field) => field !== 'id' && jobFields?.[field],
  ).length;

  function handleCloseDialog() {
    onClose();
    reset();
  }

  function handleSearchTerm(event: ChangeEvent<HTMLInputElement>) {
    setSearchTerm(event.target.value);
  }

  function toggleHideSameValues() {
    setShouldHideSameValues(!shouldHideSameValues);
  }

  const handleChangeGroup = useCallback(
    (value: 'current' | 'updated') => {
      const sectionValues = value === 'current' ? job : integrate;

      Object.keys(sectionValues).forEach((field) => {
        setValue(`selectedFields.${field}`, value);
      });
      console.log(getValues('selectedFields'));
      setValue('selectAll', value);
    },
    [setValue, job, integrate],
  );

  const handleChangeField = useCallback(
    (value: 'current' | 'updated', field: string) => {
      const sectionValues = value === 'current' ? job : integrate;
      const changedFieldValue = sectionValues?.[field as keyof typeof sectionValues];

      const areFieldsSame = Object.keys(sectionValues).every(
        (field) => getValues(`selectedFields.${field}`) === value,
      );

      if (areFieldsSame) {
        setValue('selectAll', changedFieldValue);
      } else {
        setValue('selectAll', '');
      }
    },
    [setValue, getValues, job, integrate],
  );

  async function handleConfirm() {
    const selectedFieldsValues = getValues('selectedFields');
    const selectedValues: FieldValues = {};

    Object.keys(selectedFieldsValues).forEach((field) => {
      const selectedValue = selectedFieldsValues[field as keyof typeof selectedFieldsValues];
      const value =
        selectedValue === 'current'
          ? job[field as keyof typeof job]
          : integrate[field as keyof typeof integrate];

      selectedValues[field] = value;
    });

    const formValues = { ...jobData, ...selectedValues };

    await updateJob({
      age,
      formValues,
      jobId,
      jobType,
      webStatus: (jobData as OtherJobValues)?.webstatus,
    })
      .unwrap()
      .then(() => {
        dispatch(
          openWaveSnack({
            message: `${jobId} was successfully updated`,
            type: 'success',
          }),
        );

        dispatch(jobApi.util.invalidateTags(['Job']));
        dispatch(jobFormApi.util.invalidateTags(['Steps']));
        dispatch(jobFormApi.util.invalidateTags(['StartApprovalData']));
        dispatch(workflowApi.util.invalidateTags(['Deadline', 'Workflow']));
      })
      .catch(({ message }) =>
        dispatch(
          openWaveSnack({
            message,
            type: 'error',
          }),
        ),
      );

    onClose();
  }

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen}>
      <WaveDialogTitle onClose={handleCloseDialog} title={'Integrate Update'} />

      <Box component={DialogContent} display="flex" overflow="hidden">
        <Box width="100%">
          <StyledHeaderContainer>
            <SearchBar
              isFetching={isFetching}
              onSearchTermChange={handleSearchTerm}
              onToggleHideSameValues={toggleHideSameValues}
              searchTerm={searchTerm}
              shouldHideSameValues={shouldHideSameValues}
            />
          </StyledHeaderContainer>

          {isFetching ? (
            <Skeleton height={760} variant="rectangular" />
          ) : (
            <Box component={Paper} maxHeight="60vh" sx={{ overflowY: 'auto' }}>
              <DialogHeader
                integrateIdValue={integrate.id}
                jobIdValue={job.id}
                totalFieldsCount={totalFieldsCount}
                totalUpdatedCount={totalUpdatedCount}
              />

              <StyledRadioGroup
                control={control}
                defaultValue="updated"
                name="selectAll"
                onChange={(value) => handleChangeGroup(value)}
              >
                <Box
                  bgcolor="background.paper"
                  boxShadow="0px 2px 2px rgba(0, 0, 0, 0.1)"
                  display="flex"
                >
                  <Box
                    alignItems="center"
                    display="flex"
                    marginLeft={1.5}
                    width={OFFSET_COLUMN_WIDTH}
                  >
                    <Typography>Field</Typography>
                  </Box>

                  <Box alignItems="center" display="flex" flex="1" marginRight={3}>
                    <StyledRadioButton
                      label={t('select_all_current_values', 'Select All Current Values')}
                      size="small"
                      value="current"
                    />
                  </Box>

                  <Box alignItems="center" display="flex" flex="1">
                    <StyledRadioButton
                      label={t('select_all_updated_values', 'Select All Updated Values')}
                      size="small"
                      value="updated"
                    />
                  </Box>
                </Box>
              </StyledRadioGroup>

              <Divider />

              {filteredSameValuesData.integrate.length === 0 ? (
                <Box
                  alignItems="center"
                  component={Typography}
                  display="flex"
                  height="300px"
                  justifyContent="center"
                >
                  All values in this comparison are identical.
                </Box>
              ) : (
                <DialogList
                  control={control}
                  filteredSearchData={filteredSearchData}
                  handleChangeField={handleChangeField}
                  integrate={integrate}
                  job={job}
                  jobFields={jobFields}
                  language={language}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>

      <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Button color="warning" onClick={handleCloseDialog}>
            {t('lib.dialog.cancel', 'Cancel')}
          </Button>

          <LoadingButton disabled={isFetching} loading={isLoading} onClick={handleConfirm}>
            {t('lib.confirm', 'Confirm')}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
